interface IConstraintEnum {
    readonly max: number;
    readonly min: number;
    readonly maxLength: number;
    readonly minLength: number;
    readonly pattern: RegExp;
}

interface IAddress {
    readonly street: Pick<IConstraintEnum, 'maxLength'>;
    readonly streetNumber: Pick<IConstraintEnum, 'maxLength'>;
    readonly zipCode: Pick<IConstraintEnum, 'maxLength'>;
    readonly city: Pick<IConstraintEnum, 'maxLength'>;
    readonly country: Pick<IConstraintEnum, 'maxLength'>;
}

const address: IAddress = {
    street: { maxLength: 45 },
    streetNumber: { maxLength: 5 },
    zipCode: { maxLength: 10 },
    city: { maxLength: 45 },
    country: { maxLength: 45 },
};

interface ICommon {
    email: Pick<IConstraintEnum, 'pattern' | 'maxLength'>;
    phone: Pick<IConstraintEnum, 'pattern'>;
    phoneTech: Pick<IConstraintEnum, 'pattern'>;
    url: Pick<IConstraintEnum, 'pattern'>;
}

const common: ICommon = {
    /**
     * PATTERN REFERENCE
     * The recipient's name is limited to 64 symbols.
     * The domain name is limited to 253 symbols.
     * Numbers allowed.
     * Uppercase letters are allowed.
     * Email should have recipient name, @ symbol, domain name, and first-level domain name.
     */
    email: {
        // eslint-disable-next-line max-len
        pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        maxLength: 80,
    },

    /**
     * PATTERN REFERENCE
     * Only numbers are allowed.
     * Plus prefix required. Example: +1234567890 (OK)
     * Brackets allowed. Example: +(123)4567890 (OK)
     * Minimum length 10 digits
     * Maximum length 12 digits
     * Only digits allowed. Example: 123444556a (NOT OK)
     * Plus prefix should be exactly first. Example: (+123)4567890 (NOT OK)
     * Special symbols are not allowed. Example: 123^444*55=66 (NOT OK)
     *
     * SUGGESTIONS
     * Space separators allowed. Example: +123 444 5566 (OK)
     * Space separators should be correctly placed. Example: 1 2 3 4 4 4 5 5 6 6 (NOT OK)
     * Dash separators allowed. Example: +123 444-5566 (OK)
     * Phone number brackets should always be paired. Example: (1234445566 (NOT OK) || 123)4445566 (NOT OK)
     */
    // eslint-disable-next-line no-useless-escape
    phone: { pattern: /^[\+][(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{4,7}$/im },
    phoneTech: { pattern: /^[+]?[(]?[0-9]{2,3}[)]?[-\s.*]?[0-9]{2,5}[-\s.*]?[0-9]{4,7}$/im },

    url: {
        pattern:
            // eslint-disable-next-line
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
    },
};

interface ICollectible {
    readonly description: Pick<IConstraintEnum, 'maxLength'>;
    readonly multiple: {
        editions: Pick<IConstraintEnum, 'max' | 'min'>;
    };
    readonly name: Pick<IConstraintEnum, 'maxLength'>;
    readonly price: Pick<IConstraintEnum, 'min' | 'pattern' | 'max'>;
    readonly techPrice: Pick<IConstraintEnum, 'min' | 'pattern' | 'max'>;
    readonly royalties: Pick<IConstraintEnum, 'max' | 'min' | 'pattern'>;
}

const collectible: ICollectible = {
    description: { maxLength: 2000 },
    multiple: {
        editions: { max: 100000, min: 2 },
    },
    name: { maxLength: 80 },
    techPrice: {
        min: 1,
        max: 1000000,
        /**
         * PATTERN REFERENCE
         * Price is a whole numeric value
         * Optionally you can add up to 6 digits after comma
         */
        pattern: /^[1-9][0-9]*$/,
    },
    price: {
        min: 0.000001,
        max: 1000000,
        /**
         * PATTERN REFERENCE
         * Price is a whole numeric value
         * Optionally you can add up to 6 digits after comma
         */
        pattern: /^[0-9]+(\.[0-9]{0,6})?$/,
    },
    royalties: {
        min: 0,
        max: 50,
        pattern: /^[0-9]{1,2}$/,
    },
};

interface ICollection {
    readonly description: Pick<IConstraintEnum, 'maxLength'>;
    readonly name: Pick<IConstraintEnum, 'maxLength'>;
}

const collection: ICollection = {
    description: { maxLength: 2000 },
    name: { maxLength: 80 },
};

interface ICompany {
    readonly name: Pick<IConstraintEnum, 'maxLength'>;
    readonly registrationNumber: Pick<IConstraintEnum, 'maxLength'>;
}

const company: ICompany = {
    name: { maxLength: 45 },
    registrationNumber: { maxLength: 45 },
};

interface ISocials {
    readonly url: Pick<IConstraintEnum, 'maxLength'>;
}

const socials: ISocials = {
    url: { maxLength: 80 },
};

interface IUser {
    readonly aboutMe: Pick<IConstraintEnum, 'maxLength'>;
    readonly displayName: Pick<IConstraintEnum, 'maxLength'>;
    readonly firstName: Pick<IConstraintEnum, 'maxLength'>;
    readonly lastName: Pick<IConstraintEnum, 'maxLength'>;
    readonly password: Pick<IConstraintEnum, 'minLength' | 'pattern'>;
}

const user: IUser = {
    aboutMe: { maxLength: 400 },
    displayName: { maxLength: 45 },
    firstName: { maxLength: 45 },
    lastName: { maxLength: 45 },
    /**
     * PATTERN REFERENCE
     * Minimum length 8 symbols
     * Password should contain numbers, lowercase and uppercase letters
     */
    password: {
        minLength: 8,
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
    },
};

interface ICreator {
    readonly description: Pick<IConstraintEnum, 'maxLength'>;
    readonly displayName: Pick<IConstraintEnum, 'minLength' | 'maxLength'>;
    readonly city: Pick<IConstraintEnum, 'maxLength'>;
}

const creator: ICreator = {
    displayName: {
        minLength: 2,
        maxLength: 100,
    },
    city: {
        maxLength: 45,
    },
    description: {
        maxLength: 400,
    },
};

export const constraints = { address, common, collectible, collection, company, socials, user, creator };

// TODO: Investigate if we collected all constraints here.
