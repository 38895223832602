import { theme } from 'src/features/common/app/components/theme-provider/theme';
import { TextInput } from 'src/ui/common/atoms/text-input';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';
import { IState, StateT } from './form-text-input.types';
import { Typography } from '../../atoms/typography';

const STATE_COLORS: Record<StateT, string> = {
    success: theme.colors.blue500,
    error: theme.colors.pink500,
};

export const StyledHelpMessage = styled.span<IState>`
    display: block;
    color: ${({ theme, state }) => (state ? STATE_COLORS[state] : theme.colors.gs200)};
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
`;

export const StyledLabel = styled.label<{isTechContemporary?: boolean}>`
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.title};
    margin-bottom: 10px;
    color: ${({ isTechContemporary, theme }) => isTechContemporary ? theme.colors.grey400 : theme.colors.white };

    ${media.sm`
    font-size: 16px;
  `}
`;

export const StyledTextInput = styled(TextInput)<IState>`
    ${({ state, theme }) =>
        state === 'error' &&
        `
    border-color: ${theme.colors.pink500};
    
    &:hover,
    &:focus {
      border-color: ${theme.colors.pink500};
    }
  `};
`;

export const StyledInputWrapper = styled.div`
    position: relative;
`;

export const StyledDescription = styled(Typography)`
  text-align: center;
  width: 278px;
  white-space: break-spaces;
`;

export const TooltipWrapper = styled.div`
  height: 20px;
`;

export const StyledWrapper  = styled.div`
  display: flex;
  justify-content: space-between;
`;
