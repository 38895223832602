// MODULES
import { useTranslation } from 'next-i18next';
import { ValidationRule } from 'react-hook-form';
import sanitizeHtml from 'sanitize-html';
// RESOURCES
import { constraints } from 'src/constants/constraints.constants';

export const INVALID_TAG_TEXT = 'Tags must contain text';
export const INVALID_FIELD_TEXT = (field: string) => `Incorrect ${field}`;
/*
Obsolete method. Do not use.
Method should be deleted when new rich text editor will replace TextArea in `index.tsx`.
*/
export const isTagTextValid = (text: string, field?: string) => {
    const innerText = text.replace(/<[^>]*>/g, '').trim();
    if (!innerText.length) {
        return field ? INVALID_FIELD_TEXT(field) : INVALID_TAG_TEXT;
    }
    return true;
};

export function useValidation() {
    const { t } = useTranslation(['form']);
    // =========================
    // COMMON VALIDATION METHODS
    // =========================
    interface IRequiredRules {
        required: ValidationRule<boolean>;
        setValueAs: ((v: any) => any) | undefined;
    }

    /**
     * @param message - custom validation message.
     */
    const isRequired: (message?: string) => IRequiredRules = (
        message = t('validations.isRequired', { ns: 'form' })
    ) => ({
        setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
        required: {
            value: true,
            message: message!,
        },
    });
    /**
     * @param limit - number of symbols limiting user input.
     * @param message - custom validation message.
     */
    const maxLength: (limit: number, message?: string) => { maxLength: ValidationRule<number> } = (
        limit,
        message = t('validations.maxLength', { ns: 'form', limit })
    ) => ({
        maxLength: {
            value: limit,
            message: message!,
        },
    });
    const maxFeeLValue: (limit: number, message?: string) => { max: ValidationRule<number> } = (
        limit,
        message = t('validations.maxFeeValue', { ns: 'form', limit })
    ) => ({
        max: {
            value: limit,
            message: message!,
        },
    });

    const minFeeValue: (limit: number, message?: string) => { min: ValidationRule<number> } = (
        limit,
        message = t('validations.minFeeValue', { ns: 'form', limit })
    ) => ({
        min: {
            value: limit,
            message: message!,
        },
    });
    /**
     * @param limit - number of symbols limiting user input.
     * @param message - custom validation message.
     */
    const minLength: (limit: number, message?: string) => { minLength: ValidationRule<number> } = (
        limit,
        message = t('validations.minLength', { ns: 'form', limit })
    ) => ({
        minLength: {
            value: limit,
            message: message!,
        },
    });
    /**
     * @param limit - maximum allowed value.
     * @param message - custom validation message.
     */
    const maxValue: (limit: number, message?: string) => { max: ValidationRule<number> } = (
        limit,
        message = t('validations.maxValue', { ns: 'form', limit })
    ) => ({
        max: {
            value: limit,
            message: message!,
        },
    });
    /**
     * @param limit - minimum allowed value.
     * @param message - custom validation message.
     */
    const minValue: (limit: number, message?: string) => { min: ValidationRule<number> } = (
        limit,
        message = t('validations.minValue', { ns: 'form', limit })
    ) => ({
        min: {
            value: limit,
            message: message!,
        },
    });
    /**
     * @param pattern - RegExp used to validate input format.
     * @param message - custom validation message.
     */
    const validatePattern: (pattern: RegExp, message?: string) => { pattern: ValidationRule<RegExp> } = (
        pattern,
        message = t('validations.incorrectPattern', { ns: 'form' })
    ) => ({
        pattern: {
            value: pattern,
            message: message!,
        },
    });
    // ==================
    // VALIDATION PRESETS
    // ==================
    const validateEmail: () => { pattern: ValidationRule<RegExp> } = () => ({
        ...maxLength(constraints.common.email.maxLength),
        ...validatePattern(constraints.common.email.pattern, t('validations.format.email', { ns: 'form' })),
    });
    const validateDescription = () => ({
        maxLength: (v: string) =>
            sanitizeHtml(v, { allowedTags: [] }).trim().length <= constraints.collectible.description.maxLength ||
            (t('validations.maxLength', {
                ns: 'form',
                limit: constraints.collectible.description.maxLength,
            }) as string),
        notEmpty: (v: string) =>
            !!sanitizeHtml(v, { allowedTags: [] }).trim().length ||
            (t('validations.isRequired', { ns: 'form' }) as string),
    });

    interface IPasswordValidationRules {
        minLength: ValidationRule<number>;
        pattern: ValidationRule<RegExp>;
    }

    const validatePassword: () => IPasswordValidationRules = () => ({
        ...minLength(constraints.user.password.minLength),
        ...validatePattern(constraints.user.password.pattern, t('validations.format.password', { ns: 'form' })),
    });
    const validatePhone: () => { pattern: ValidationRule<RegExp> } = () => ({
        ...validatePattern(constraints.common.phone.pattern, t('validations.format.phone', { ns: 'form' })),
    });

    const validateTechPhone: () => { pattern: ValidationRule<RegExp> } = () => ({
        ...validatePattern(constraints.common.phoneTech.pattern, t('validations.format.phone', { ns: 'form' })),
    });

    interface IPriceValidationRules {
        min: ValidationRule<number>;
        pattern: ValidationRule<RegExp>;
    }

    const validatePrice: (min?: number, max?: number) => IPriceValidationRules = (min, max) => ({
        ...minValue(typeof min === 'undefined' ? constraints.collectible.price.min : min),
        ...(typeof max !== 'undefined' ? maxValue(max) : {}),
        ...validatePattern(constraints.collectible.price.pattern, t('validations.format.price', { ns: 'form' })),
    });

    const validateTechPrice: (min?: number, max?: number) => IPriceValidationRules = (min, max) => ({
        ...minValue(typeof min === 'undefined' ? constraints.collectible.techPrice.min : min),
        ...maxValue(typeof max === 'undefined' ? constraints.collectible.techPrice.max : max),
        ...validatePattern(
            constraints.collectible.techPrice.pattern,
            t('validations.format.techPrice', { ns: 'form' })
        ),
    });

    interface IRoyaltiesValidationRules {
        max: ValidationRule<number>;
        min: ValidationRule<number>;
    }

    const validateRoyalties: () => IRoyaltiesValidationRules = () => ({
        ...minValue(constraints.collectible.royalties.min),
        ...maxValue(constraints.collectible.royalties.max),
        ...validatePattern(
            constraints.collectible.royalties.pattern,
            t('validations.format.royalties', { ns: 'form' })
        ),
    });
    return {
        isRequired,
        maxLength,
        minLength,
        maxValue,
        minValue,
        validateEmail,
        validateDescription,
        validatePassword,
        validatePattern,
        validatePhone,
        validateTechPhone,
        validatePrice,
        validateTechPrice,
        validateRoyalties,
        maxFeeLValue,
        minFeeValue,
    };
}