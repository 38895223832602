/**
 * Libs
 */
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

/**
 * Components
 */
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';

/**
 * Types
 */
import { IFormTextInput } from '../form-text-input/form-text-input.types';

/**
 * Styles
 */
import { StyledHelpMessage, StyledLabel, StyledTextInput } from '../form-text-input/form-text-input.styles';
import { StyledPasswordInput } from './password-input.styles';

export const PasswordInput = React.forwardRef(({ state, label, error, message, ...rest }: Omit<IFormTextInput, 'type'>, ref: React.ForwardedRef<HTMLInputElement>) => {
    const [revealed, setRevealed] = useState(false);

    const inputId = useMemo(() => rest?.id ?? uuid(), [rest?.id]);

    const togglePasswordCallback = useCallback(() => {
        setRevealed((currentState) => !currentState);
    }, []);

    return (
        <StyledPasswordInput>
            {label && <StyledLabel htmlFor={inputId}>{label}</StyledLabel>}
            <div>
                <StyledTextInput
                    stretch
                    ref={ref}
                    type={revealed ? 'text' : 'password'}
                    id={inputId}
                    state={error ? 'error' : undefined}
                    {...rest}
                />
                <button type='button' onClick={togglePasswordCallback}>
                    <SvgIcon
                        variant='regular'
                        icon={!revealed ? 'eyeOff' : 'eye'}
                        color='#B7B7B7'
                    />
                </button>
            </div>
            {message && <StyledHelpMessage state={state}>{message}</StyledHelpMessage>}
            {error && <StyledHelpMessage state='error'>{error}</StyledHelpMessage>}
        </StyledPasswordInput>
    );
});

PasswordInput.displayName = 'PasswordInput';
