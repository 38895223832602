import styled from 'styled-components';

export const StyledPasswordInput = styled.div`
    & > div {
        position: relative;
    }

    input {
        margin: 0;
        padding-right: 48px;
    }

    button {
        cursor: pointer;
        user-select: none;
        display: grid;
        place-items: center;
        margin: 0;
        appearance: none;
        border: none;
        background: none;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
`;
